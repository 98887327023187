import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { Box, Typography } from "@mui/material";

export default function Sender(props) {
	const [animate, setAnimate] = useState(false);
	const { content } = props;
	const { type } = content;
	const message = get(content, "config.message", "");
	
	let ReplyContent = null;

	// Determine the content to render based on type
	switch (type) {
	case "msg":
		ReplyContent = (
			<>
				{message && (
					<Typography variant="body1" className="sender-message-text">
						{message}
					</Typography>
				)}
			</>
		);
		break;
	default:
		console.warn(`Unrecognized type ${type} of sender content.`);
	}

	// Trigger animation on content change
	useEffect(() => {
		if (content) {
			setAnimate(true);
			const timeout = setTimeout(() => setAnimate(false), 500); // Match animation duration
			return () => clearTimeout(timeout);
		}
	}, [content]);

	return (
		<Box className="sender-ui">
			{/* Container for the sender message */}
			<Box
				className={`sender-message-container ${animate ? "animate-fade-in-up" : ""}`}
			>
				{ReplyContent}
			</Box>
		</Box>
	);
}
