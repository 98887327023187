import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Box, Typography, CardHeader } from "@mui/material";
import { IoChevronBack } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

import useSettingsSelector from "./../../store/selectors/settings";

const Docs = () => {
	const navigate = useNavigate();
	const { selectedDoc } = useSettingsSelector();
	window.parent.postMessage({ increaseSize: true },"*");

	const handleBack = () => {
		navigate("/private/home");
	};
	const hideWidget = () => {
		window.parent.postMessage({ hideWidget: true }, "*");
	};
	const Header = () => (
		<CardHeader className="basic-header"
			avatar={
				<IconButton aria-label="back" onClick={handleBack}>
					<IoChevronBack />
				</IconButton>
			}
			action={
				<IconButton aria-label="close" onClick={hideWidget}>
					<IoMdClose />
				</IconButton>
			}
			title={
				<Typography variant="body1" className="basic-header-title">
                    Document
				</Typography>
			}
		/>
	);
	return (
		<>
			<Header />
			<Box sx={{ padding: 2, display: 'flex', flexDirection: 'column'}} className="doc-iframe">
				{/* Using an iframe to embed the HTML document */}
				{selectedDoc && selectedDoc.url && (
					<iframe
						src={selectedDoc.url}
						width="100%"
						height="100%" // You can adjust the height
						frameBorder="0"
						title="Document Content"
						style={{ flexGrow: 1 }}
					/>
				)}
				{!selectedDoc && (
					<Typography variant="body1">Document is not found</Typography>
				)}
			</Box>
		</>
	);
};

export default Docs;