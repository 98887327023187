import React, { useContext } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import {
	Box,
	Stack,
	Typography,
	ListItemText,
	ListItemButton,
	List,
} from "@mui/material";
import { Context } from "../../store/auth";

const ChatNavigation = ({ conversation, meta }) => {
	const navigate = useNavigate();
	const { setPage } = useContext(Context);
	// Retrieve the localized text for "Start new conversation" from the metadata
	const startConvLocale = meta?.locale?.find(el => el.field === "start_new_conversation");
	// Handle navigation to the chat page

	const handleNavigation = async () => {
		await new Promise(resolve => {
			// Update the application state to set the page to "chat"
			setPage({
				location: "chat",
				props: {
					conversationId: conversation ? conversation._id : null, // Pass the conversation ID if available
					agentIds: [], // Initialize with an empty array of agent IDs
				},
			});
			resolve(); // Ensure the state update completes before continuing
		});

		// Navigate to the chat page with the appropriate state
		navigate("/private/chat", {
			state: {
				location: "chat",
				conversationId: conversation ? conversation._id : null, // Pass the conversation ID for context
				agentIds: [], // Pass the agent IDs to initialize the conversation
			},
		});
	};

	return (
		<Box>
			{/* Navigation list for starting or continuing a conversation */}
			<List className="basic-navigation-list" sx={{background: "#f5f8fd"}}>
				<ListItemButton onClick={handleNavigation}>
					<ListItemText>
						<Stack direction="row" spacing={1} className="basic-navigation-stack">
							{/* Display the start conversation text */}
							<Typography variant="body1">
								{startConvLocale?.value || "Start new conversation"}
							</Typography>
						</Stack>
					</ListItemText>
					<KeyboardArrowRightIcon />
				</ListItemButton>
			</List>
		</Box>
	);
};

export default ChatNavigation;
