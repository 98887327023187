import React from "react";
import { Stack, Box } from "@mui/material";
import { INTELSWIFT_GRAY_LOGO } from "../../constants";

const TypingIndicator = () => (
	<Stack direction="row" spacing={1} className="typing-indicator-container">
		<>{INTELSWIFT_GRAY_LOGO}</>
		<Box className="typing-indicator-body">
			<div className="dot"></div>
			<div className="dot"></div>
			<div className="dot"></div>
		</Box>
	</Stack>
);

export default TypingIndicator;
