var W3CWebSocket = require("websocket").w3cwebsocket;

import eventEmitter from './components/EventEmitter';

class SocketClient {
	#ws = null;
	static instance = null;
	constructor(tenantId, contactId, botId) {
		if (SocketClient.instance) {
			return SocketClient.instance; // Return the existing instance
		}

		this.tenantId = tenantId;
		this.contactId = contactId;
		this.botId = botId;

		SocketClient.instance = this;

		this.initializeSocket();
	}

	initializeSocket(){
		try{
			this.#ws = new W3CWebSocket(`${process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:8000"}?origin=wws&tenantId=${this.tenantId}&contactId=${this.contactId}_${this.botId}`);

			this.setEventListeners();
		}catch(error){
			console.error("@@ WWS @@ socket exception  ", error);
		}
	}
	setEventListeners(){
		this.#ws.onopen = () => {
			console.warn("@@ WWS @@ WebSocket connection established");
		};

		this.#ws.onclose = (event) => {
			this.cleanupListeners();  // Cleanup listeners
			if (event.reason === 'removing_old_connection') {
				console.warn("@@ WWS @@ Removing old connection without reconnect");
				return;
			}
			console.warn("@@ WWS @@ socket closed, reconnecting", event);
			this.reconnect();
		};

		this.#ws.onerror = (error) => {
			console.error('@@ WWS @@ socket error:', error.message);
			this.cleanupListeners();  // Cleanup listeners
			this.reconnect();
		};

		this.#ws.onmessage = (event) => {
			try{
				const parsedMessage = JSON.parse(event.data);

				const { topic, message } = parsedMessage;
				switch(topic) {
				case "ping":
					const jsonMessage = JSON.stringify({
						topic: "pong",
						message: {
							tenantId: this.tenantId,
							contactId: this.contactId,
						},
					});
					eventEmitter.emit('agents_update', { ...message });
					this.#ws.send(jsonMessage);
					break;
				case "record_update":
					eventEmitter.emit('record_update', { ...message });
					break;
				case "contact_update":
					eventEmitter.emit('contact_update', { ...message });
					break;
				case "assistant_typing":
					eventEmitter.emit('assistant_typing', { ...message });
					break;
				case "conversation_assignee":
					eventEmitter.emit('conversation_assignee', { ...message });
					break;
				default:
					console.warn("@@ WWS @@ Socket topic is not handled ", topic);
				}
			}catch(error){
				console.error("@@ Error: this.#ws.onmessage @@", error);
			}
		};
	}
	cleanupListeners() {
		if (this.#ws) {
			this.#ws.onopen = null;
			this.#ws.onclose = null;
			this.#ws.onerror = null;
			this.#ws.onmessage = null;
		}
	}
	reconnect() {
		if(this.#ws){
			this.#ws.close();
		}
		setTimeout(() => {
			console.warn("@@ WWS @@ Trying to reconnect to socket for...");
			this.initializeSocket();
		}, 2000);
	}
	get client(){
		return this.#ws;
	}
	static getInstance(tenantId, contactId, botId) {
		if (!SocketClient.instance) {
			SocketClient.instance = new SocketClient(tenantId, contactId, botId);
		}
		return SocketClient.instance;
	}

}
export default SocketClient;
