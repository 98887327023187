import React, { useState, useEffect, useRef, memo, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { get } from "lodash";
import { IoMdSend } from "react-icons/io";
import { IoChevronBack } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import {
	Box,
	FormControl,
	InputAdornment,
	OutlinedInput,
	IconButton,
	CardHeader
} from "@mui/material";
import History from "./history";
import Footer from "../../components/Footer";
import eventEmitter from "./../../components/EventEmitter";
import AvatarStack from "./../../components/AvatarStack";
import TypingIndicator from "../../components/TypingIndicator";
import { Context } from "../../store/auth";
import notificationSound from "../../assets/sounds/notification.wav";

// Create an Axios instance for API calls
const axiosInstance = axios.create({
	baseURL:
		process.env.REACT_APP_BACK_END_BASE_URL || "http://localhost/atombot/api/v1"
});

// Main Chat component
const Chat = (props) => {
	window.parent.postMessage({ decreaseSize: true }, "*");
	const { botId, uuid, lang, tenantId, projectUUID } = props;
	const { widget, setPage, conversations, setConversations, contact, setContact, agents, isMuted } = useContext(Context);
	const { state } = useLocation();
	const inputRef = useRef();
	const pageProps = state || {};
	const navigate = useNavigate();
	const pagePropsRef = useRef(pageProps);
	const agentsRef = useRef(null);
	const [autoFocus, setAutoFocus] = useState(false);
	const [assignedAgents, setAssignedAgents] = useState([]);
	const [records, setRecords] = useState([]);
	const recordsRef = useRef(records);
	const [initialized, setInitialized] = useState(false);
	const [assistantTyping, setAssistantTyping] = useState(false);
	const [query, setQuery] = useState("");
	const [lastQuery, setLastQuery] = useState("");
	const [initialQuery, setInitialQuery] = useState("");
	const locales = get(widget, "meta.locale", []);
	const sendMsgLocale = locales.find((el) => el.field === "send_msg");
	const [dataCollector, setDataCollector] = useState(null);
	// const [ticket, setTicket] = useState(null);
	const [contactDataCollector, setContactDataCollector] = useState(null);
	const [isMultiline, setIsMultiline] = useState(false);
	const [numRows, setNumRows] = useState(1);
	const [emailError, setEmailError] = useState(false);

	// Function to update assigned agents 
	const updateAssignedAgents = (assignees) => {
		if (!Array.isArray(assignees) || assignees.length === 0) {
			console.warn("updateAssignedAgents: No valid assignees, skipping update.");
			return;
		}
		const updatedAgents = assignees
			.map((assigneeId) => agentsRef.current.find((agent) => agent.agentIDaasId === assigneeId))
			.filter(Boolean); // Remove any undefined values
		setAssignedAgents(updatedAgents);
	};

	// Function to fetch assigned agents 
	const handleConvAssignee = (message) => {
		const { assignees, newConvId, prevConvId } = message;
		if (!prevConvId || !newConvId) {
			console.error("handleConvAssignee: Invalid conversation IDs", message);
			return;
		}
		if (Array.isArray(assignees)) {
			updateAssignedAgents(assignees);
		}
		setConversations((prevConversations) =>
			prevConversations.map((conversation) =>
				conversation._id === prevConvId ? { ...conversation, _id: newConvId } : conversation
			)
		);
		if (
			pagePropsRef.current?.location === "chat" &&
			pagePropsRef.current?.conversationId === prevConvId
		) {
			setAssignedAgents(() => {
				const updatedAgents = assignees
					.map((assigneeId) =>
						agentsRef.current?.find((agent) => agent.agentIDaasId === assigneeId)
					)
					.filter(Boolean);
				const updatedPage = {
					...pagePropsRef.current,
					conversationId: newConvId,
					assignedAgents: updatedAgents,
				};

				pagePropsRef.current = updatedPage;
				setPage(updatedPage);
				return updatedAgents;
			});
		}
	};

	// Function to send a request to the backend
	const handleRequest = async (content) => {
		try {
			await axiosInstance.post("/widgets/query", {
				content,
				tenantId,
				botId,
				uuid: projectUUID,
				contactId: uuid,
				lang,
				href: window.location.hostname,
			});
		} catch (error) {
			console.error("Error in handleRequest:", error);
		}
	};

	// Handling user message submission 
	const handleSubmit = async () => {
		console.log('handle submnit')
		console.log('initial query state', initialQuery)

		if(!initialQuery && !contactDataCollector){
			console.log('set initial query to ', query)
			setInitialQuery(query)
		}
		if (!query.trim()) {
			setQuery("");
			return;
		}
		setLastQuery(query);
		if (handleDataCollector()) return;
		// if (handleTicket()) return;
		if (handleContactDataCollector()) return;
		await handleDefaultMessage();
		setQuery("");
	};

	// Function to handle DataCollector logic
	const handleDataCollector = () => {

		if (!dataCollector) return false;
		const updatedAttrs = dataCollector.innerAttrs.map((attr) =>
			attr.field === dataCollector.currAttr?.field ? { ...attr, value: query.trim() } : attr
		);
		
		const allAttrsFilled = updatedAttrs.every((attr) => attr.value.trim() !== "");
		
		setDataCollector((prev) => {
			
			return {
				...prev,
				innerAttrs: updatedAttrs,
				currAttr: allAttrsFilled ? null : prev.currAttr,
			};
		});
		setQuery("");
		return true;
	};

	// Function to handle Ticket logic
	// const handleTicket = () => {
	// 	if (!ticket) return false;
	// 	const updatedAttrs = ticket.innerAttrs.map((attr) =>
	// 		attr.field === ticket.currAttr?.field ? { ...attr, value: query.trim() } : attr
	// 	);
	// 	const currentField = ticket.currAttr?.type.toLowerCase();
	// 	if (currentField === "email") {
	// 		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	// 		if (!emailPattern.test(query.trim())) {
	// 			setEmailError(true);
	// 			return true;
	// 		}
	// 		setEmailError(false);
	// 	}
	// 	setTicket((prev) => ({
	// 		...prev,
	// 		innerAttrs: updatedAttrs,
	// 	}));
	// 	setQuery("");
	// 	return true;
	// };

	// Function to handle Contact DataCollector logic
	const handleContactDataCollector = () => {
		if (!contactDataCollector) return false;

		const updatedAttrs = contactDataCollector.innerAttrs.map((attr) =>
			attr.field === contactDataCollector.currAttr?.field
				? { ...attr, value: query.trim() }
				: attr
		);

		const currentField = contactDataCollector.currAttr?.name;
		if (currentField === "Email") {
			const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!emailPattern.test(query.trim())) {
				setEmailError(true);
				return true;
			}
			setEmailError(false);
		}
	
	

		const nextAttr = updatedAttrs.find((attr) => !attr.value);
		setQuery("");
		if (!nextAttr) {
			const { content } = contactDataCollector;
			setContactDataCollector(null);
			console.log('request prev query', initialQuery)
			console.log('req to backend', {
				conversationId: pagePropsRef.current?.conversationId,
				action_query: {
					intent: content.config.intent,
					prevQuery: content?.flow === 6 ? null : initialQuery, // escalation flow prevent ai from answering
					nextWorkflowId: content?.config?.nextWorkflowId, // send nextWorkflowid
					meta: content,
					data: {
						attrs: updatedAttrs
					}
				},
			});
			handleRequest({
				conversationId: pagePropsRef.current?.conversationId,
				action_query: {
					intent: content.config.intent,
					prevQuery: content?.flow === 6 ? null : initialQuery, // escalation flow prevent ai from answering
					nextWorkflowId: content?.config?.nextWorkflowId, // send nextWorkflowid
					meta: content,
					data: {
						attrs: updatedAttrs
					}
				},
			});

			// Clear stored initial query
			setInitialQuery("");

		} else {
			setContactDataCollector((prev) => ({
				...prev,
				innerAttrs: updatedAttrs,
				currAttr: nextAttr,
			}));
		}

		return true;
	};

	// Function to handle default message submission
	const handleDefaultMessage = async () => {
		const conversationId = pagePropsRef.current?.conversationId;
		await handleRequest({
			conversationId: conversationId || null, 
			msg_query: { text: query },
		});
	};
	
	// Initialize a session and fetch contact details if no records exist
	const initSessionAndGetContact = async (_records) => {
		if (_records.length === 0) {
			await handleRequest({
				conversationId: pagePropsRef.current?.conversationId || null,
				new_session: true, 
			});
		}
	};
	
	// Handle assistant typing indicator
	const handleAssistantTyping = (message) => {
		const { conversationId } = message;
		if (pagePropsRef.current?.conversationId === conversationId) {
			setAssistantTyping((prev) => {
				if (!prev) {
					return true;
				}
				return prev;
			});
		}
	};

	// Dynamically adjusting the text input field's properties
	const updateTextFieldProperties = () => {
		const isTextArea =
			dataCollector?.currAttr?.type === "textarea" ||
			contactDataCollector?.currAttr?.type === "textarea";
		setIsMultiline(isTextArea);
		setNumRows(isTextArea ? 4 : 1);
	};

	// Function to play the notification sound
	const playNotificationSound = () => {
		const audio = new Audio(notificationSound);
		audio.play().catch((error) => {
			console.error("Error playing notification sound:", error);
		});
	};

	// Pre-filter logic before updating state
	const isValidRecord = (rec) => {
		if (rec.query?.type === "msg") return true;
		if (rec.reply?.type === "contact_data_collector") return true;
		if (["ticket_data_collector", "connect_data_collector"].includes(rec.reply?.type) && Array.isArray(rec.reply?.config?.attrs)) {
			const hasEmptyAttr = rec.reply.config.attrs.some(attr => !attr.value);
			if (!hasEmptyAttr) return false;
		}
		// if (rec.reply?.type === "ticket" && Array.isArray(rec.reply?.config?.attrs)) {
		// 	const nonDescriptionAttrs = rec.reply.config.attrs.filter(attr => attr.field !== "description");
		// 	if (nonDescriptionAttrs.length === 0) return false;
		// }
		if (!rec.reply && !rec.query?.config?.message) {
			return false;
		}
		return true;
	};

	// Updating the state of data collectors
	const updateCollectorState = (reply, setCollector) => {
		if (!reply) return;
		const attrs = reply?.config?.attrs || reply.attrs;
		if (!Array.isArray(attrs) || attrs.length === 0) return;
		setCollector((prev) => {
			const innerAttrs = attrs.map((attr) => ({
				...attr,
				value: attr?.value || "",
			}));
			const currentAttr = innerAttrs.find((attr) => !attr.value);
			return {
				...prev,
				content: reply,
				innerAttrs,
				currAttr: currentAttr,
			};
		});
	};

	// Function to handle record updates
	const handleRecordUpdate = (message) => {
		const { contactId, record } = message;
		if (contactId !== `${uuid}_${botId}`) return;
		if (pagePropsRef.current?.location === "chat" && pagePropsRef.current?.conversationId === null) {
			const updatedPage = {
				location: "chat",
				...pagePropsRef.current,
				conversationId: record.conversationId,
			};
			setPage(updatedPage);
			pagePropsRef.current = updatedPage;
		}
		setConversations((prevConversations) => {
			return prevConversations.map((conversation) => {
				if (conversation.placeholder && !conversation._id) {
					conversation._id = record.conversationId;
				}
				if (conversation._id === record.conversationId) {
					let recordExists = false;
					const filteredRecords = conversation.records
						.filter(isValidRecord)
						.map((rec) => {
							if (rec._id === record._id) {
								recordExists = true;
								return { ...record, isIncoming: true };
							}
							return rec;
						});
					if (!recordExists && isValidRecord(record)) {
						conversation.records = [...filteredRecords, { ...record, isIncoming: true }];
					} else {
						conversation.records = filteredRecords;
					}
				}
				return conversation;
			});
		});
		// if (record.reply?.type === "data_collector") {
		// 	updateCollectorState(record.reply, setDataCollector);
		// }
		if (["ticket_data_collector", "connect_data_collector"].includes(record.reply?.type)) {
			updateCollectorState(record.reply, setDataCollector);
		}
		if (record.reply?.type === "contact_data_collector") {
			updateCollectorState(record.reply, setContactDataCollector);
		}
		if (record.reply?.agentIDaasId && !isMuted) {
			playNotificationSound();
		}
		const lastRecordReply = record.reply;
		if (lastRecordReply) {
			setAssistantTyping(false);
		}
	};

	// Updating the contact details in the chat
	const handleContactUpdate = (message) => {
		const { contact: updatedContact } = message;
		if (updatedContact) {
			setContact(updatedContact);
		}
	};

	// Navigate back to the previous page
	const handleBack = () => {
		const previousPage = pagePropsRef.current?.origin || "home";
		if (previousPage === "conversations") {
			setPage({ location: "conversations", props: {} });
			navigate("/private/conversations");
		} else {
			setPage({ location: "home", props: {} });
			navigate("/private/home");
		}
	};

	// Function to hide the widget by sending a message to the parent window
	const hideWidget = () => {
		window.parent.postMessage({ hideWidget: true }, "*");
	};

	// Handling the progress of data collection flow
	useEffect(() => {
		if (!dataCollector) return;
		const nextAttr = dataCollector.innerAttrs.find((attr) => !attr.value);

		if (nextAttr && nextAttr !== dataCollector.currAttr) {
			setDataCollector((prev) => ({
				...prev,
				currAttr: nextAttr,
			}));
		} else if (!nextAttr) {
			const content = dataCollector.content;
			handleRequest({
				conversationId: pagePropsRef.current?.conversationId,
				action_query: {
					actionId: content?.actionId,
					intent:content.config.intent,
					meta: content,
					data: {
						attrs: dataCollector.innerAttrs
					}
				},
			});
			setDataCollector(null);
		}
	}, [dataCollector]);

	// Handling the progress of ticket collection flow
	// useEffect(() => {
	// 	if (!ticket) return;
	// 	const nextAttr = ticket.innerAttrs.find((attr) => !attr.value);
	// 	if (nextAttr && nextAttr !== ticket.currAttr) {
	// 		setTicket((prev) => ({
	// 			...prev,
	// 			currAttr: nextAttr,
	// 		}));
	// 	} else if (!nextAttr) {
	// 		console.log("ticket ", ticket);	
	// 		handleRequest({
	// 			conversationId: pagePropsRef.current?.conversationId,
	// 			action_query: {
	// 				actionId: ticket.content?.action?.actionId,
	// 				flow: ticket.content?.action?.flow,
	// 				callback: {
	// 					ticket: {
	// 						attrs: ticket.innerAttrs,
	// 						priority: ticket?.content?.action?.callback?.data?.priority || "Low",
	// 						agent: ticket?.content?.agent,
	// 						segmentId: ticket?.content?.segmentId,
	// 					},
	// 				},
	// 			},
	// 		});
	// 		setTicket(null);
	// 		setDataCollector(null);
	// 	}
	// }, [ticket]);

	// Handling the progress of contact data collection flow
	useEffect(() => {
		if (!contactDataCollector) return;
		const remainingAttrs = contactDataCollector.innerAttrs.filter(
			(attr) => !(attr.field in (contact?.meta || {})) || contact.meta[attr.field] === ""
		);
		const nextAttr = remainingAttrs.find((attr) => !attr.value);

		if (nextAttr && nextAttr !== contactDataCollector.currAttr) {
			setContactDataCollector((prev) => ({
				...prev,
				currAttr: nextAttr,
				innerAttrs: remainingAttrs,
			}));
		} else if (!nextAttr) {
			setContactDataCollector(null);
		}
	}, [contactDataCollector, contact?.meta]);

	// Automatically trigger handleRequest if article exists
	useEffect(() => {
		if (pagePropsRef.current?.article) {
			handleRequest({
				conversationId: pagePropsRef.current?.conversationId,
				msg_query: { text: pagePropsRef.current?.article },
			});
		}
	}, [pageProps.article, pageProps.conversationId]);

	// Synchronizing the conversation state with the records displayed in the chat
	useEffect(() => {
		if (conversations && pagePropsRef.current) {
			if (pagePropsRef.current.conversationId) {
				// Try to find conversation by conversationId
				const conversation = conversations.find(conv => conv._id === pagePropsRef.current.conversationId);
				const _records = conversation?.records || [];
				setRecords(_records);
				if (conversation?.placeholder === true && !initialized) {
					initSessionAndGetContact(_records);
					setInitialized(true);
				}
			} else {
				// If no conversationId, find an existing placeholder conversation
				const existingPlaceholder = conversations.find(conv => conv.placeholder === true);
				if (existingPlaceholder) {
					const _records = existingPlaceholder?.records || [];
					if(Array.isArray(_records) && _records.length === 0){
						initSessionAndGetContact([]);
					}else{
						setRecords(existingPlaceholder.records || []);
					}
				} else if (!initialized) {
					// If no placeholder exists, trigger new session
					initSessionAndGetContact([]);
				}
				setInitialized(true);
			}
		}
	}, [pagePropsRef, conversations]);	
	

	// Sync agents with agentsRef
	useEffect(() => {
		if (agents) {
			agentsRef.current = agents;
			const conversation = conversations?.find(
				(conv) => conv._id === pagePropsRef.current?.conversationId,
			);
			if (conversation?.assignees) {
				updateAssignedAgents(conversation.assignees);
			}
		}
	}, [agents, conversations, pagePropsRef]);

	useEffect(() => {
		updateTextFieldProperties();
	}, [dataCollector, contactDataCollector]);

	// Sync reords with recordsRef
	useEffect(() => {
		recordsRef.current = records;
	}, [records]);

	// Sync pageProps with pagePropsRef
	useEffect(() => {
		pagePropsRef.current = pageProps;
	}, [pageProps]);

	// Sync agentProps with pagePropsRef
	useEffect(() => {
		agentsRef.current = agents;
	}, [agents]);

	// Attach event listeners for assistant typing, record updates, and assignee changes
	useEffect(() => {
		const listeners = [
			["assistant_typing", handleAssistantTyping],
			["record_update", handleRecordUpdate],
			["conversation_assignee", handleConvAssignee],
			["contact_update", handleContactUpdate],
		];
		listeners.forEach(([event, handler]) => eventEmitter.on(event, handler));
		return () => {
			listeners.forEach(([event, handler]) => eventEmitter.off(event, handler));
		};
	}, []);
	useEffect(() => {
		if (navigator.userAgent.match(/iPhone|iPad|iPod/)) {
		  // Disable autofocus specifically for Safari
		  setAutoFocus(false);
		}else{
		  setAutoFocus(true);
		}
	}, []);
	// Header component for the chat interface
	const Header = () => {
		return (
			<CardHeader className="basic-header"
				avatar={(
					<IconButton aria-label="back" onClick={handleBack}>
						<IoChevronBack />
					</IconButton>
				)}
				action={
					<IconButton aria-label="close" onClick={hideWidget}>
						<IoMdClose />
					</IconButton>
				}
				title={(
					<Box className="agents-info">
						<AvatarStack
							{...props}
							agents={assignedAgents || []}
							displayAgentInfo={true}
						/>
					</Box>
				)}
			/>
		);
	};
	const handleFocus = () => {
		setTimeout(() => {
			inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}, 200); // Delay to make sure focus happens before scrolling
		setTimeout(() => {
			document.querySelector('meta[name="viewport"]')?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, height=device-height');
		}, 200); // Allow time for the keyboard to open
	};
	// Render the Chat component
	return (
		<Box className="chat-container">
			<Header assignedAgents={pageProps.assignedAgents || []} />
			<History
				{...props}
				locales={locales}
				records={records}
				query={lastQuery}
				setLastQuery={setLastQuery}
				handleRequest={handleRequest}
				agents={agents}
				conversationId={pagePropsRef.current?.conversationId}
			/>
			{assistantTyping && (
				<TypingIndicator />
			)}
			<FormControl
				variant="outlined"
				fullWidth
				size="small"
				className="chat-input"
			>
				<OutlinedInput
					ref={inputRef}
					onKeyPress={(event) => event.key === "Enter" && handleSubmit()}
					onChange={(e) => { setQuery(e.target.value); }}
					className="send-message-textfield"
					onFocus={handleFocus} 
					value={query}
					autoFocus={autoFocus}
					multiline={isMultiline}
					rows={numRows}
					error={emailError}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="open chat screen"
								edge="end"
								color="primary"
								disabled={query.trim() === ""}
								onClick={handleSubmit}
							>
								<IoMdSend />
							</IconButton>
						</InputAdornment>
					}
					placeholder={
						dataCollector?.currAttr?.name ||
						// ticket?.currAttr?.name ||
						contactDataCollector?.currAttr?.name ||
						sendMsgLocale?.name ||
						"Ask me anything"
					}
				/>
			</FormControl>
			<Footer />
		</Box>

	);
};

export default memo(Chat);