import React from "react";
import { Box } from "@mui/material";
import { INTELSWIFT_GRAY_LOGO } from "../../constants";

const Footer = () => {
	return (
		<Box className="footer-box">
			{/* Footer content: logo and link */}
			<Box className="footer-content">
				{INTELSWIFT_GRAY_LOGO}
				<span className="footer-text">
                    Powered by{" "}
					<a
						href="https://intelswift.com/"
						className="footer-link"
						target="_blank"
						rel="noreferrer"
					>
                        Intelswift
					</a>
				</span>
			</Box>
		</Box>
	);
};

export default Footer;
