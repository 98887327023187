import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Box, List, ListItemButton, ListItemText, CardHeader, Typography } from "@mui/material";
import { IoChevronBack } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Context } from "../../store/auth";
import Footer from "../../components/Footer";
import useSettingsSelector from "./../../store/selectors/settings";

// Articles component to display a list of FAQs and navigate to chat with selected questions
const Articles = () => {
	// Extract `widget` and `conversations` from the Context
	const { widget, conversations } = useContext(Context);
	const { setSelectedDoc } = useSettingsSelector();
	window.parent.postMessage({ decreaseSize: true },"*");
	// Find the placeholder conversation, used as the default conversation
	const conversation = conversations?.find((conv) => conv.placeholder === true) || null;
	// Extract FAQs (articles) from the widget metadata
	const articles = widget?.pages?.faqs?.docs || [];
	const navigate = useNavigate();
	// Navigate back to the home page
	const handleBack = () => {
		navigate("/private/home");
	};

	// Hide the widget by sending a message to the parent window
	const hideWidget = () => {
		window.parent.postMessage({ hideWidget: true }, "*");
	};

	// Header component for the Articles page
	const Header = () => (
		<CardHeader className="basic-header"
			avatar={
				<IconButton aria-label="back" onClick={handleBack}>
					<IoChevronBack />
				</IconButton>
			}
			action={
				<IconButton aria-label="close" onClick={hideWidget}>
					<IoMdClose />
				</IconButton>
			}
			title={
				<Typography variant="body1" className="basic-header-title">
                    Help Center
				</Typography>
			}
		/>
	);

	// Render the Articles component layout
	return (
		<Box className="articles-container">
			<Box className="articles-body">
				<Header />
				<List className="articles-list">
					{Array.isArray(articles) &&
                        articles.map((option, index) => (
                        	<ListItemButton
                        		key={index}
                        		onClick={() => {
                        			setSelectedDoc(option.doc);
                        			navigate("/private/docs");
                        		}}
                        	>
                        		<ListItemText>{option.doc.title}</ListItemText>
                        		<KeyboardArrowRightIcon />
                        	</ListItemButton>
                        ))}
				</List>
			</Box>
			<Footer />
		</Box>
	);
};

export default Articles;
