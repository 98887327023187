import React from "react";
import Message from "./TextMessage";
import { Button, Stack, Box, IconButton } from "@mui/material";
import { Link } from 'react-router-dom';
import {get} from "lodash";

const MessageAndButtons = (props) => {
	const { content, handleRequest, conversationId } = props;
	const buttons = get(content, "config.buttons", get(content, 'buttons', null));

	const TextButton = ({ el }) => {
		if (el.url) {
			return (
				<Link to={el.url} target="_blank">
					<Button
						variant="outlined"
						sx={{
							borderColor: "lightgrey",
							color: "black",
							borderRadius: "12px",
							width: "fit-content",
							justifyContent: "center",
						}}
					>{el.value}</Button>
				</Link>
			);
		}
		return (
			<Button
				variant="outlined"
				sx={{
					borderColor: "lightgrey",
					color: "black",
					borderRadius: "12px",
					width: "fit-content",
					justifyContent: "center",
				}}
				onClick={() => {
					handleRequest({
						conversationId: conversationId,
						callback_query: {
							button: el.button,
							meta: content
						}
					});
				}}
			>{el.value}</Button>
		);
	};



	const EmojiButton = ({ el }) => {
		return (
			<IconButton
				sx={{
					width: "fit-content",
					justifyContent: "center",
				}}
				onClick={() => {
					handleRequest({
						conversationId: conversationId,
						callback_query: {
							button: el.button,
							meta: content
						}
					});
				}}
			>
				{el.value === "Negative" && <span role="img" aria-label="heart">😡</span>}
				{el.value === "Neutral" && <span role="img" aria-label="heart">😐</span>}
				{el.value === "Positive" && <span role="img" aria-label="heart">😄</span>}
			</IconButton>
		);
	};
	return (
		<>
			<Message {...props} />
			<Stack direction={"row"} spacing={1}
				sx={{ width: "100%", margin: "10px 0", justifyContent: "flex-start", alignItems: "end", flexWrap: "wrap" }}>
				{buttons.map((el, idx) => {
					return (
						<Box 
							key={`${el.value}${idx}`}
							sx={{
								marginTop: "8px !important",
								marginLeft: "8px !important"
							}}
						>
							{content.flow === 2 ? <EmojiButton el={el} /> 
								: 
								<TextButton el={el} />}
						</Box>
					);
				})}
			</Stack>
		</>
	);
};

export default MessageAndButtons;