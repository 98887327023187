import React from "react";
import TextMessage from "./TextMessage";

const ContactDataCollector = ({ content }) => {

	return (
		<>
			{/* Render message */}
			{content?.config?.message && (
				<TextMessage
					content={{
						config: {
							message: content.config.message
						},
					}}
				/>
			)}
		</>
	);
};

export default ContactDataCollector;