import { createContext, useState, useMemo } from "react";
const Context = createContext();

const ContextProvider = (props) => {
	const {
		children
	} = props;

	const [widget, setWidget] = useState(null);
	const [agents, setAgents] = useState([]);
	const [conversations, setConversations] = useState([]);
	const [contact, setContact] = useState(null);
	const [isMuted, setIsMuted] = useState(false);
	const [page, setPage] = useState({
		location: "home",
		props: {}
	});

	const value = useMemo(()=> ({
		widget,
		page,
		agents,
		conversations,
		contact,
		isMuted,
		setPage,
		setWidget,
		setAgents,
		setConversations,
		setContact,
		setIsMuted
	}), [widget, agents, page, conversations, contact, isMuted]);
	return (
		<Context.Provider value={value}>
			{children}
		</Context.Provider>
	);
};

export {
	Context,
	ContextProvider
};