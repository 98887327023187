import React, { memo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CiImageOn } from "react-icons/ci";
import { Card, CardContent, CardHeader, CardMedia, Stack, Typography } from "@mui/material";

import {
	RecentConversations,
	ChatNavigation,
	ConversationsNavigation,
	ArticlesNavigation,
} from "../../components/Navigation";
import {formatTimeAgo, trimString} from "./../../utils/common";
import useSettingsSelector from "./../../store/selectors/settings";


// Home component to render navigation and recent conversations
const Home = (props) => {
	const navigate = useNavigate();
	const { setSelectedDoc } = useSettingsSelector();
	window.parent.postMessage({ decreaseSize: true },"*");
	// Destructure props to extract relevant data
	const { faqs, homeDocs, agents, faqsEnabled, conversations, widget } = props;
	// Find the placeholder conversation (used for new or inactive conversations)
	const placeholderConv = conversations?.find((conv) => conv.placeholder === true) || null;
	// Determine if FAQs, agents, and conversations exist
	const hasFaqs = Array.isArray(faqs) && faqs.length > 0; // Check if there are FAQs
	const hasAgents = Array.isArray(agents) && agents.length > 0; // Check if there are agents
	const hasConversations = Array.isArray(conversations) && conversations.filter((conv) => conv.placeholder !== true).length > 0; // Check if there are valid conversations

	// Render the ArticlesNavigation component if FAQs exist
	const renderArticlesNavigation = () => {
		if (hasFaqs) {
			return (
				<ArticlesNavigation
					articles={faqs}
					meta={widget.meta}
					conversationId={placeholderConv?._id}
				/>
			);
		}
		return null;
	};

	// Render the ConversationsNavigation component if agents and conversations exist
	const renderConversationsNavigation = () => {
		if (hasAgents && hasConversations) {
			return <ConversationsNavigation meta={widget.meta} />;
		}
		return null;
	};

	// Render the RecentConversations component if agents exist
	const renderRecentConversations = () => {
		if (hasAgents) {
			return (
				<RecentConversations
					{...props}
					meta={widget.meta}
				/>
			);
		}
		return null;
	};

	// Render the ChatNavigation component
	const renderChatNavigation = () => {
		return (
			<ChatNavigation
				meta={widget.meta}
				conversation={placeholderConv}
			/>
		);
	};
	const renderMediaContent = (imageSrc) => {
		if (!imageSrc) {
			// No image source, show the icon and set background color
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '185px',
						backgroundColor: '#f0f0f0', // Light grey background
					}}
				>
					<CiImageOn size={40} color="#aaa" />
				</div>
			);
		}
		// If image source exists, show the image
		return (
			<CardMedia
				component="img"
				height='185px'
				image={imageSrc} // Use fallback if imageSrc is empty or broken
				alt="card image"
			/>
		);
	};
	const renderDocs = () => {
		return (
			<>
				{homeDocs && homeDocs.length > 0 && (
					<Stack direction="row" spacing={1} sx={{ width: "fit-content", overflowX: "scroll", maxWidth: "400px", paddingBottom: "10px", marginLeft: "-8px !important", paddingRight: "5px !important" }}>
						{homeDocs.map((el, idx) => {
							const doc = el.doc;
							return (
								<Card 
									key={idx}
									sx={{
										width: "300px", 
										height: "400px", 
										maxHeight: "400px", 
										borderRadius: "16px",
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										marginLeft: "8px !important", 
										marginTop: "10px !important",
										flexShrink: "0"
									}}
									className="hover-pointer"
									onClick={()=>{
										setSelectedDoc(doc);
										navigate('/private/docs');
									}}
								>
									<CardHeader 
										title={<Typography variant="body1">{trimString(doc.title, 35)}</Typography>}
										subheader={formatTimeAgo(doc.updatedAt)}
									/>
									{renderMediaContent(doc.media)}
									<CardContent>
										<Typography 
											variant="body1" 
											sx={{ color: 'text.secondary' }}
										>
											{trimString(doc.description, 120)}
										</Typography>
									</CardContent>
								</Card>
							);
						})}
					</Stack>
				)}
			</>
		);
	};
	// Render the Home component's layout
	return (
		<Stack direction="column" spacing={1} className="home-container">
			{faqsEnabled && renderArticlesNavigation()} {/* Render navigation for FAQs */}
			{renderConversationsNavigation()} {/* Render navigation for conversations */}
			{renderRecentConversations()} {/* Render recent conversations */}
			{renderChatNavigation()} {/* Render navigation for chat */}
			{renderDocs()}
		</Stack>
	);
};

export default memo(Home);
