import { createTheme } from "@mui/material/styles";

export const DefaultTheme = createTheme({
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					"& .MuiOutlinedInput-notchedOutline": {
						border: `1px solid #BBB4FF`,
					},
					"&.Mui-focused": {
						"& .MuiOutlinedInput-notchedOutline": {
							border: `2px solid #BBB4FF`,
						},
					},
					"&.Mui-disabled": {
						"& .MuiOutlinedInput-notchedOutline": {
							border: `1px solid #CCC`, // Dashed border when disabled
						},
						color: "#A0A0A0", // Gray text color when disabled
					}
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				outlined: {
					'&.MuiInputLabel-shrink': {
						color: "black"
					},
				},
			},
		}
	}
});
